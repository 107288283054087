import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { IoChevronBack } from "react-icons/io5";
import useAppchains from "../../hooks/deployments/useappchains";
import { useParams } from "react-router-dom";
import { useStateContext } from 'contexts/ContextProvider';
import GeneralSettings from "components/project/settings/GeneralSettings";
import TeamManagement from "components/project/settings/TeamManagement";
import WalletIntegration from "components/project/settings/WalletIntegration";
import ManageProjectSubscription from "components/project/settings/ManageProjectSubscription";


const defaultTabs = [
    { id: "general", name: "General Settings" },
    { id: "team", name: "Team Management" },
    // { id: "wallet", name: "Wallet Integration" },
];



const ProjectSettings = () => {
    const [tabs, setTabs ] = useState(defaultTabs)
    const [activeTab, setActiveTab] = useState("general");
    const { projectId } = useParams();
    const { project, fetchProject, appChains} = useAppchains(projectId);
    const { user } = useStateContext();
    
    // manage permissions to only allow creator of project to delete or cancel subscription
    useEffect(() => {
        if (project && user ){
            if (user.email === project.created_by){
                setTabs([...defaultTabs, { id: "subscription", name: "Manage Subscription" }])
            }
        }
      return () => {
        setTabs(defaultTabs)
      }
    }, [project, user])
    


    return (
        <div className="flex">
            <div className="mr-8 p-8 md:p-16">
                <BackButton />
            </div>
            <div className="container mx-auto px-6 py-10 bg-[#020215] text-white min-h-screen p-8 md:p-16">
                {/* Header */}
                <div className="mb-8">
                    <h1 className="text-2xl font-semibold">Project Settings</h1>
                </div>
                {/* Tabs */}
                <div className="mb-6 border-b border-gray-600">
                    <nav className="flex space-x-4">
                    {tabs.map((tab) => (
                        <button
                        key={tab.id}
                        className={`py-2 px-4 border-b-2 ${
                            activeTab === tab.id
                            ? "border-white text-white"
                            : "border-transparent text-gray-400 hover:text-white"
                        }`}
                        onClick={() => setActiveTab(tab.id)}
                        >
                        {tab.name}
                        </button>
                    ))}
                    </nav>
                </div>
                {/* Tab Content */}
                <div>
                    {activeTab === "general" && <GeneralSettings project={project} fetchProject={fetchProject} />}
                    {activeTab === "team" && <TeamManagement project={project} fetchProject={fetchProject} />}
                    {activeTab === "wallet" && <WalletIntegration appChains={appChains} />}
                    {activeTab === "subscription" && <ManageProjectSubscription project={project} fetchProject={fetchProject} />}
                </div>

            </div>
        </div>
    );
};


const BackButton = () => (
    <motion.button
        className="flex items-center text-white space-x-2 focus:outline-none hover:text-gray-300 transition-colors duration-200"
        onClick={() => window.history.back()}
        whileHover={{ scale: 1 }}
    >
        <IoChevronBack className="text-xl" />
        <span>Back</span>
    </motion.button>
);

export default ProjectSettings;
