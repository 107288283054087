import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStateContext } from 'contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { FaDiscord, FaXTwitter, FaBars, FaMinus } from "react-icons/fa6";
import quasmLogo from "imgs/quasm_trans.png";

const Navbar = () => {
  const { logout } = useStateContext();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('/');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user } = useStateContext();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const displayedName = useMemo(() => user?.email.split("@")[0], [user]);

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
    setIsMobileMenuOpen(false); // Close mobile menu on link click
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-nav-bg text-white border-b border-borderc z-50">
      {/* Top Nav */}
      <div className="flex items-center justify-between p-4 md:px-6 md:py-2">
        <div className="flex text-lg font-medium text-white/80">
          <img src={quasmLogo} width={50} alt={"Quasm - Appchain as a Service"}/>
          <span className={"pl-2 pt-3"}>{displayedName || "Anon"}</span>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="md:hidden p-2"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle mobile menu"
          >
            {isMobileMenuOpen ? <FaMinus size={24} /> : <FaBars size={24} />}
          </button>
          <div className="hidden md:flex items-center space-x-4">
            {/* <div
              className="bg-[#3C351B] text-[#FFB800] py-1 px-3 rounded-full border border-yellow-200 cursor-pointer"
              onClick={() => navigate('/alpha-signup')}
            >
              Alpha
            </div> */}
            <div className="hover:bg-white/5 rounded p-2 cursor-pointer" onClick={() => window.open('https://discord.gg/5BSWHGnpFA', '_blank')}>
              <FaDiscord size={24} />
            </div>
            <div className="hover:bg-white/5 rounded p-2 cursor-pointer" onClick={() => window.open('https://twitter.com/QuasmIO', '_blank')}>
              <FaXTwitter size={24} />
            </div>
            <a href="https://insigh.to/b/quasm-features" className="text-sm border border-white/10 hover:text-white/90 px-4 py-2 rounded hover:bg-white/5 transition duration-200" target="_blank" rel="noopener noreferrer">
              Your Feedback
            </a>
            <button className="border px-4 py-2 rounded bg-white text-black text-sm hover:text-gray-600 cursor-pointer transition duration-100" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>

      {/* Bottom Nav */}
      <div className={`md:flex ${isMobileMenuOpen ? 'block' : 'hidden'} md:block bg-nav-bg md:bg-transparent mb-2`}>
        <ul className="flex flex-col md:flex-row md:items-center md:space-x-4 ml-4 md:ml-6">
          <NavItem to="/" label="Overview" activeLink={activeLink} onClick={handleNavLinkClick} />
          <NavItem to={"/explore"} label={"Explore"} activeLink={activeLink} onClick={handleNavLinkClick}/>
          <NavItem to={"/marketplace"} label={"Marketplace"} activeLink={activeLink} onClick={handleNavLinkClick}/>
          {/* <NavItem to={"/payment-history"} label={"Payments"} activeLink={activeLink} onClick={handleNavLinkClick}/> */}
          <NavItem to="/support" label="Support" activeLink={activeLink} onClick={handleNavLinkClick} special />
        </ul>
      </div>
    </nav>
  );
};

const NavItem = ({ to, label, activeLink, onClick, special }) => (
  <li className={`${activeLink === to ? 'pb-2 border-b-2 border-white' : ''} mb-2 md:mb-0`}>
    <NavLink
      to={to}
      className={`text-md hover:text-gray-400 ${special ? `text-blue-100 text-md hover:text-gray-300 bg-[#223257] rounded-full px-4 py-2`
          : 'text-gray-200'}`}
      onClick={() => onClick(to)}
    >
      {label}
    </NavLink>
  </li>
);

export default Navbar;
